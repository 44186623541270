import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import NavBar from './navbar';
import App from '../App';

class Menu extends Component {

  render() {
    if(this.props.isOpen) {
      return (
        <div id="openMenu">
        <button id='closeMenu' onClick={() => this.props.closeMenu()}><img src='./images/close-nav.png' /></button>
          <ul id="navList">
            <li onClick={() => {this.props.stateTransition('home'); this.props.closeMenu()}}>home</li>
            <li onClick={() => {this.props.stateTransition('location'); this.props.closeMenu()}}>location</li>
            <li onClick={() => {this.props.stateTransition('menu'); this.props.closeMenu()}}>menu</li>
            <li onClick={() => {this.props.stateTransition('about'); this.props.closeMenu()}}>about us</li>
            <li onClick={() => {this.props.stateTransition('contact'); this.props.closeMenu()}}>contact</li>
          </ul>

          <section id="menuFooter">
            <div>
              <img src='./images/dabbil-menu-logo.png' />
            </div>
            <section id='menuSocialLinks'>
              <div><img src='./images/instagram-icon.png' /></div>
              <div><img src='./images/facebook-icon.png' /></div>
              <div><img src='./images/twitter-icon.png' /></div>
            </section>
            <small>your friendly neighborhood donut truck</small>
          </section>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default Menu;
