import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Menu from './menu';
import App from '../App';

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {menuOpen: false};
    this.closeMenu = this.closeMenu.bind(this);
  }

  render() {
    return (
      <div>
        <div id='navLogo' onClick={() => this.props.stateTransition('home')}><img src='./images/dabbil-nav-logo.png' /></div>
        <button id ='navBurger' onClick={() => this.openMenu()}><img src='./images/nav-burger.png' /></button>
        <button id='navMenu' onClick={() => this.props.stateTransition('menu')}>menu</button>
        <Menu
          stateTransition={this.props.stateTransition}
          isOpen={this.state.menuOpen}
          closeMenu={this.closeMenu}>
        </Menu>
      </div>
    );
  }

   openMenu() {
    this.setState(state => ({
      menuOpen: true
    }));
  }

   closeMenu() {
    this.setState(state => ({
      menuOpen: false
    }));
  }
}



export default NavBar;
