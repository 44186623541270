import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class HomePage extends Component {

  render() {
    if (this.props.homePage) {
      return (
        <div>
          <h1>donuts & coffee</h1>
          <h2 id="homeH2">serving the ASU Tempe campus area.</h2>

          <section>
            <div id='homePageMap'>

              <div id='centerHomeMapDiv'>
                <div><img src='./images/location-pin.svg' /></div>
                <h3 id='mapFindUs'>find out where we are now</h3>
                <button onClick={() => this.props.stateTransition('location')}>let's go!</button>
              </div>
            </div>
          </section>

          <section id='homePromotion'>
            <div>
              <h3>get a FREE donut on your birthday!</h3>
            </div>
          </section>
        </div>
      );
    } else {
      return null;
    }

  }
}



export default HomePage;
