import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class ContactPage extends Component {

  render() {
    if (this.props.contactPage) {
      return (
        <div>
          <h1>contact us</h1>
          <section id='topContact'>
            <section id='phoneContact'>
                <div class='contactInfo'>
                  <p>phone & text</p>
                  <p>+123 456 5555</p>
                </div>
            </section>

            <section id='emailContact'>
                <div class='contactInfo'>
                  <p>email</p>
                  <p>hello@dabbil.com</p>
                </div>
            </section>
          </section>

          <section id='contactForm'>
            <section id='formInfo'>
              <h2>send us a message.</h2>
              <p>
              let us know how we are doing, ask a question, or just
              say a friendly hello!
              </p>
            </section>
            <section id='inputForm'>
              <form>
                <input type="text" placeholder="name" />
                <input type="email" placeholder="email" />
                <textarea>your message</textarea>
                <input type="button" value="send" />
              </form>
            </section>
          </section>
        </div>
      );
    } else {
      return null;
    }
  }
}



export default ContactPage;
