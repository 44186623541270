import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class Footer extends Component {
  render() {
    return (
      <div>
        <h1>we're social!</h1>
        <div id='footerSocialLinks'>
          <div><img src='./images/instagram-icon.png' /></div>
          <div><img src='./images/facebook-icon.png' /></div>
          <div><img src='./images/twitter-icon.png' /></div>
        </div>
      </div>
    );
  }
}

export default Footer;
