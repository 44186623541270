import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import MapContainer from './mapContainer';

class LocationPage extends Component {

  render() {
    if (this.props.locationPage) {
      return (
        <div>
          <section id='locationHead'>
            <h1>location</h1>
            <h2>hours of operation</h2>
            <p><span>Mon - Friday</span>: 6am to 4pm</p>
            <p><span>Saturday</span>: 8am to 2pm</p>
          </section>

          <section id='mapAPIsection'>
            <MapContainer />
          </section>

          <section id='locationPromotion'>
            <div>
              <h3>Check us out @dabbil on social media!</h3>
            </div>
          </section>
        </div>
      );
    } else {
      return null;
    }

  }
}



export default LocationPage;
