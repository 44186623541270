import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import NavBar from './nav/navbar';
import Footer from './footer/footer';
import HomePage from './main/home';
import AboutPage from './main/about';
import ContactPage from './main/contact';
import MenuPage from './main/menu';
import LocationPage from './main/location';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      homePage: true,
      locationPage: false,
      menuPage: false,
      contactPage: false,
      aboutPage: false
    };
    this.stateTransition = this.stateTransition.bind(this);
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <NavBar
            stateTransition={this.stateTransition}
          />
        </header>
        <main>
          <HomePage
            homePage={this.state.homePage}
            stateTransition={this.stateTransition}
          />
          <LocationPage
            locationPage={this.state.locationPage}
          />
          <MenuPage
            menuPage={this.state.menuPage}
            stateTransition={this.stateTransition}
          />
          <ContactPage
            contactPage={this.state.contactPage}
          />
          <AboutPage
            aboutPage={this.state.aboutPage}
            stateTransition={this.stateTransition}
          />
        </main>
        <Footer />
      </div>
    );
  }

  stateTransition(toState) {
    switch(toState) {
      case 'home':
        this.setState(state => ({
          homePage: true,
          locationPage: false,
          menuPage: false,
          contactPage: false,
          aboutPage: false
        }));
        break;
      case 'location':
        this.setState(state => ({
          homePage: false,
          locationPage:  true,
          menuPage:  false,
          contactPage:  false,
          aboutPage:  false
        }));
        break;
      case 'menu':
        this.setState(state => ({
          homePage : false,
          locationPage : false,
          menuPage : true,
          contactPage : false,
          aboutPage : false
        }));
        break;
      case 'contact':
        this.setState(state => ({
          homePage : false,
          locationPage : false,
          menuPage : false,
          contactPage : true,
          aboutPage : false
        }));
        break;
      case 'about':
        this.setState(state => ({
          homePage : false,
          locationPage : false,
          menuPage : false,
          contactPage : false,
          aboutPage : true
        }));
        break;
    }
  }


}

export default App;
