import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import MenuPage from '../menu';

class TeaView extends Component {

  render() {
    if (this.props.teaView) {
      return (
        <div>
          <section class='foodViewHead'>
            <h2>tea</h2>
            <button onClick={() => this.props.stateTransition('menuView')}><span>&#10229;</span> back</button>
          </section>

          <section class='foodContainter'>
            <section id='blackTea' class='foodBox'>
                <div class='foodInfo'>
                  <h3>Black Tea</h3>
                </div>
            </section>

            <section id='greenTea' class='foodBox'>
                <div class='foodInfo'>
                  <h3>Green Tea</h3>
                </div>
            </section>

            <section id='herbalTea' class='foodBox'>
                <div class='foodInfo'>
                  <h3>Herbal Tea</h3>
                </div>
            </section>
          </section>
        </div>
      );
    } else {
      return null;
    }
  }
}



export default TeaView;
