import React, {Component} from 'react'
import { render } from 'react-dom'
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'

class MapContainer extends Component {
  constructor() {
    super();
    this.state = {
      lat: 33.422113,
      lng: -111.934916,
      zoom: 17
    }
  }
  render(){
    const position = [this.state.lat, this.state.lng];
    const map = (
      <Map center={position} zoom={this.state.zoom}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
        />
        <Marker position={position}>
          <Popup>A pretty CSS3 popup.<br />Easily customizable.</Popup>
        </Marker>
      </Map>
    )
    return (
      map
    );
  }
}
 export default MapContainer;
