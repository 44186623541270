import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import MenuPage from '../menu';

class CoffeeView extends Component {

  render() {
    if (this.props.coffeeView) {
      return (
        <div>
          <section class='foodViewHead'>
            <h2>coffee</h2>
            <button onClick={() => this.props.stateTransition('menuView')}><span>&#10229;</span> back</button>
          </section>

          <section class='foodContainter'>
            <section id='regCoffee' class='foodBox'>
                <div class='foodInfo'>
                  <h3>Regular Coffee</h3>
                </div>
            </section>

            <section id='latteCoffee' class='foodBox'>
                <div class='foodInfo'>
                  <h3>Coffee Latte</h3>
                </div>
            </section>

            <section id='mochaCoffee' class='foodBox'>
                <div class='foodInfo'>
                  <h3>Coffee Mocha</h3>
                </div>
            </section>
          </section>
        </div>
      );
    } else {
      return null;
    }
  }
}



export default CoffeeView;
