import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import MenuPage from '../menu';

class DonutView extends Component {

  render() {
    if (this.props.donutView) {
      return (
        <div>
          <section class='foodViewHead'>
            <h2>donuts</h2>
            <button onClick={() => this.props.stateTransition('menuView')}><span>&#10229;</span> back</button>
          </section>

            <section class='foodContainter'>
              <section id ='glazedDonut' class='foodBox'>
                  <div class='foodInfo'>
                    <h3>Glazed Donut</h3>
                  </div>
              </section>

              <section id ='streuselDonut' class='foodBox'>
                  <div class='foodInfo'>
                    <h3>Streusel Donut</h3>
                  </div>
              </section>

              <section id ='cocoDonut' class='foodBox'>
                  <div class='foodInfo'>
                    <h3>Toasted Coconut Donut</h3>
                  </div>
              </section>

              <section id ='berryDonut' class='foodBox'>
                  <div class='foodInfo'>
                    <h3>Fresh Berry Donut</h3>
                  </div>
              </section>

              <section id ='oreoDonut' class='foodBox'>
                  <div class='foodInfo'>
                    <h3>Oreo Donut</h3>
                  </div>
              </section>
            </section>
        </div>
      );
    } else {
      return null;
    }
  }
}



export default DonutView;
