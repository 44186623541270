import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import MenuPage from '../menu';

class MenuList extends Component {

  render() {
    if (this.props.menuView) {
      return (
        <div id='menuView'>
          <section id='menuListHead'>
            <h2>donuts & coffee</h2>
            <p>the freshest donuts and smoothest coffee you'll find around.</p>
          </section>

          <section
            class='itemTab' id='donutTab'
            onClick={() => this.props.stateTransition('donutView')}
          >
            <button>donuts</button>
            <div><img src='./images/donut-menu-tab.jpg' /></div>
          </section>

          <section
            class='itemTab' id='coffeeTab'
            onClick={() => this.props.stateTransition('coffeeView')}
          >
            <button>coffee</button>
            <div><img src='./images/coffee-menu-tab.jpg' /></div>
          </section>

          <section
            class='itemTab' id='teaTab'
            onClick={() => this.props.stateTransition('teaView')}
          >
            <button>tea</button>
            <div><img src='./images/tea-menu-tab.jpg' /></div>
          </section>
        </div>
      );
    } else {
      return null;
    }
  }
}



export default MenuList;
