import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import MenuList from './menu/menulist';
import DonutView from './menu/donuts';
import CoffeeView from './menu/coffee';
import TeaView from './menu/tea';

class MenuPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuView: true,
      donutView: false,
      coffeeView: false,
      teaView: false
    };
    this.stateTransition = this.stateTransition.bind(this);
  }

  render() {
    if (this.props.menuPage) {
      return (
        <div>
          <section>
            <h1>menu</h1>
          </section>

          <section>
            <MenuList
              menuView={this.state.menuView}
              stateTransition={this.stateTransition}
            />
            <DonutView
              donutView={this.state.donutView}
              stateTransition={this.stateTransition}
            />
            <CoffeeView
              coffeeView={this.state.coffeeView}
              stateTransition={this.stateTransition}
            />
            <TeaView
              teaView={this.state.teaView}
              stateTransition={this.stateTransition}
            />
          </section>
        </div>
      );
    } else {
      return null;
    }
  }

  stateTransition(toState) {
    switch(toState) {
      case 'menuView':
        this.setState(state => ({
          menuView: true,
          donutView: false,
          coffeeView: false,
          teaView: false
        }));
        break;
      case 'donutView':
        this.setState(state => ({
          menuView: false,
          donutView: true,
          coffeeView: false,
          teaView: false
        }));
        break;
      case 'coffeeView':
        this.setState(state => ({
          menuView: false,
          donutView: false,
          coffeeView: true,
          teaView: false
        }));
        break;
      case 'teaView':
        this.setState(state => ({
          menuView: false,
          donutView: false,
          coffeeView: false,
          teaView: true
        }));
        break;
    }
  }
}

export default MenuPage;
