import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class AboutPage extends Component {

  render() {
      if (this.props.aboutPage) {
        return (
        <div>
          <h1>meet dabbil</h1>
          <section id='aboutUs'>
            <p>
            What's dabbil? Only your friendly neighborhood donut truck! We serve
            only the freshest and most flavor packed donuts you can find around!
            We know what its like to be on the run around campus and that's why
            we are here; to serve only the best donuts and coffee to busy students
            on the go.
            </p>
            <p>
            Trust us, we're alums, we know what its like.
            </p>
          </section>

          <section id='founders'>
            <div id='founder1'>
              <div id='founder1img' class='founderImg'></div>
              <div id='founder1desc'>
                <h2>Leslie</h2>
                <h3>Founder</h3>
                <p>Professional Donut Taster</p>
              </div>
            </div>
            <div id='founder2'>
            <div id='founder2img' class='founderImg'></div>
              <div id='founder2desc'>
                <h2>Jessi</h2>
                <h3>Co-founder</h3>
                <p>Donut Handler & <br />Coffee Sipper</p>
              </div>
            </div>
          </section>

          <section id='aboutPromo'>
            <div>
              <h3>You've met us, now we'd love to meet you!</h3>
              <button onClick={() => this.props.stateTransition('contact')}>contact us</button>
            </div>
          </section>
        </div>
      );
    } else {
      return null;
    }
  }
}



export default AboutPage;
